//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: [],
    data () {
        return {
            form: {
                name: '',
                age: 0,

                class: ''
            }
        }
    },
    methods: {
        submitHandler() {
            //若不使用submit-url则使用submit事件提交；
            this.$post('', this.form, data => {
                //完成提交
            });
        }
    },
    mounted: function() {
        
    }
}
